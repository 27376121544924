import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavTabs from './components/navtabs';
import Login from './components/login';
import SignUp from './components/signup';
import ForgotPassword from "./components/forgotPassword";
import Footer from './components/footer/footer'

function Home() {
    const [loginShow, setLoginShow] = useState(false);
    const [signUpShow, setSignUpShow] = useState(false);
    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);

    const smallscreen = window.matchMedia("(max-width: 768px)").matches;

  
    return (
      <Container fluid className="p-0">
        <NavTabs login={setLoginShow} signup={setSignUpShow}/>
        <Container fluid className="p-0">
          <Row className="p-0 py-4 m-0" style={{width: '100%', minHeight:'91vh'}}>
            <Col lg="6"  className="p-0 m-0 ps-xs-0 ps-lg-5 d-flex justify-content-center align-items-center" style={{minHeight:'80vh'}}>
              <Card style={{maxWidth:'550px', backgroundColor:'transparent', border:'none', padding:'1rem'}}>
                <Card.Title>
                  <div className="d-flex m-0 mt-2">
                    <img src="/images/joshpic.png" alt="Profile" height="70" className="rounded-circle"></img>
                    <Card.Text height="70" style={{fontSize:'70%'}} className="d-flex align-items-center text-center ms-2">With experience in kennels and vets Josh will take good care of your pup ensuring they have maximum fun while maintaining a safe environment!</Card.Text>
                  </div>
                </Card.Title>
                <Card.Img alt="bones" src="/images/dog&bones.png"/>
                <Card.ImgOverlay className="d-flex justify-content-center align-items-center">
                  <Button variant="dark" onClick={() => setSignUpShow(true)}
                  style={{marginRight:'17%', marginTop:'45%', border:'2px solid #4E2D21', backgroundColor:'transparent', color:'#4E2D21', fontWeight:'bold', fontSize:'90%',padding:'5px'}}
                  >Sign Up to Book Walks Today</Button>
                </Card.ImgOverlay>
                <Card.Title className="d-flex" style={{flexWrap:'wrap', justifyContent:'center'}}><Card.Text className="me-2">Alternatively Whatsapp/Call Us!!</Card.Text><a href="tel:+447360187898">07360 187898</a></Card.Title>
              </Card>
            </Col>
            <Col lg="6"  className="p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight:'60vh'}}>
              <Card style={{width:'100%',maxWidth:'550px', backgroundColor:'transparent', border:'none', padding:'1rem', textAlign:'center'}}>
                <Card.Title id="meetup"> or Book an initial meet up here</Card.Title>
                {/* <MeetupCalendar/> */}
                <iframe
                  title="Wapping Walkies Calendar"
                  src="https://wappingwalkies.youcanbook.me/"
                  height="500"
                ></iframe>
              </Card>
            </Col>
          </Row>
          <Row className="p-0 py-4 m-0" style={{width: '100%', minHeight:'100vh'}}>
            <Col lg="12"  className="p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight:'60vh'}}>
              <Card style={{height:'100%', backgroundColor:'transparent', border:'none', padding:'1rem'}}>
                <Card.Img alt="blob" src="/images/blob.png" height="100%"/>
                <Card.ImgOverlay className="d-flex justify-content-center align-items-center" style={{flexDirection:'column', marginTop:'25%'}}>
                  <Card.Title id="about" style={{backgroundColor:'#EBE2B5',marginBottom:'0', borderRadius:'20px'}}>About Wapping Walkies</Card.Title>
                  <Card.Text style={{margin:'22%', marginTop:'2vh',backgroundColor:'#EBE2B5', borderRadius:'30px',}}>
                  Hi, my name is Josh! I spent my younger years working in kennels, on farms and in veterinary clinics as I originally intended to become a vet. 
                  I ended up becoming an investment consultant (not a very similar field) but have now moved to working for myself giving me free reign over my hours.
                  This has allowed me to go back to doing what I truly enjoy and consequently I have set up Wapping Walkies where I can provide the best, most flexible care for your pup.
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
          <Row className="p-0 py-4 m-0" style={{width: '100%'}}>
            <Col lg="12"  className="p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight:'10vh'}}>
              <Card style={{ backgroundColor:'transparent', border:'none', padding:'1rem'}}>
                <Card.Title id="map" style={{backgroundColor:'#EBE2B5',padding:'5px', borderRadius:'20px'}}>Live Map Coming Soon!</Card.Title>
              </Card>
            </Col>
          </Row>
        </Container>
        <Login show={loginShow} onHide={() => setLoginShow(false)} forgotPassword={setForgotPasswordShow} signup={setSignUpShow}/>
        <SignUp show={signUpShow} onHide={() => setSignUpShow(false)} login={setLoginShow}/>
        <ForgotPassword show={forgotPasswordShow} onHide={() => setForgotPasswordShow(false)} signup={setSignUpShow}/>
        <Footer/>
      </Container>
    );
  }
  
  export default Home