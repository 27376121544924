import React, { useRef, useState } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import { useAuth } from "../contexts/authContext"
import { Link, useNavigate } from "react-router-dom"

export default function Login(props) {
  const [show, setShow] = useState(true); // State variable to control modal visibility
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()
    try {setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate("/mydogs")
    } catch {setError("Failed to log in")}
    setLoading(false)}

    const handleClose = () => {
      setShow(false); // Update the state to close the modal
      if (props.onHide) {props.onHide()}};

  return (
    <Modal show={props.show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title style={{textAlign:'center'}}>Sign in to see your Pup Profile</Modal.Title>
        {error && <Alert variant="danger">{error}</Alert>}
      </Modal.Header>
      <Modal.Body>         
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group id="password" className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100 mt-3" type="submit">
            Log In
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="flex justify-content-center">
        <div className="w-100 text-center mt-3">
          <Link onClick={() => { handleClose(); props.forgotPassword(true); }}>Forgot Password?</Link>
        </div>
        <div className="w-100 text-center mt-3">
        Need an account? <Link onClick={() => { handleClose(); props.signup(true); }}>Sign Up</Link>
        </div>
      </Modal.Footer>
    </Modal>
  )
}