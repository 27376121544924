import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "./contexts/authContext"

const PrivateRoute = ({ redirectPath = "/", children }) => {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}

export default PrivateRoute