import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import './footer.css'

const Footer = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Formspree endpoint
    const formSpreeEndpoint = 'https://formspree.io/f/moqzneyo';

    // Assuming you have an input with the ID 'email' for the subscriber's email
    const email = document.getElementById('email').value;

    // Send a POST request to Formspree
    try {
      const response = await fetch(formSpreeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      if (response.ok) {
        alert('Subscription successful!'); // You can replace this with a more user-friendly notification
      } else {
        alert('Subscription failed. Please try again.'); // You can replace this with a more user-friendly notification
      }
    } catch (error) {
      console.error('Error submitting subscription:', error);
      alert('An error occurred. Please try again later.'); // You can replace this with a more user-friendly notification
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <p className="copyright">&copy; {new Date().getFullYear()} Wapping Walkies</p>
        </div>
        <div className="footer-section">
          <a href="/documents/privacy_policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="/documents/terms_conditions.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
        </div>
        <div className="footer-section contact">
          <h4>Contact Us</h4>
          <p>Phone Us! <a href="tel:+447360187898">07360 187 898</a></p>

        <div className="footer-section social-icons">
          <a href="https://www.facebook.com/wappingwalkies" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/WappingWalkies_" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/wappingwalkies/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
        </div>
        <div className="footer-section newsletter">
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Subscribe to a newsletter of cute dogs:</label>
            <div className="input-group">
              <input type="email" id="email" name="email" placeholder="Enter your email" required />
              <button type="submit">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;