import React, { useContext, useState, useEffect } from "react"
import { auth } from "../../firebase"
import { createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, signOut, updateEmail,
  updatePassword, updateProfile, sendPasswordResetEmail,
  onAuthStateChanged } from "firebase/auth";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth,email)
  }

  function emailUpdate(email) {
    return updateEmail(auth.currentUser,email)
  }

  function passwordUpdate(password) {
    return updatePassword(auth.currentUser,password)
  }

  function nameUpdate(displayname) {
    return updateProfile(auth.currentUser,{
      displayName: displayname
    })
  }

  function photoUpdate(downloadURL) {
    return updateProfile(auth.currentUser,{ photoURL: downloadURL })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    emailUpdate,
    passwordUpdate,
    nameUpdate,
    photoUpdate
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}