import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navtabs(props) {
  const brown = {color: '#4E2D21'}

  return (
    <Navbar expand="none" className="py-2 m-0 px-0" style={{backgroundColor:'#FFE8B8', padding:'5px', minHeight:'9vh',}}>
      <Container fluid className='d-flex justify-content-space-between px-xs-1 px-lg-5'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" variant="light"/>
        <Navbar.Brand><img alt="logo" src="/images/wwdoglogo.png" height="36" className="d-inline-block align-top"></img><img alt="logo" src="/images/wwdashlogo.png" height="36" className="d-inline-block align-top"></img></Navbar.Brand>
        <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic" className="p-0 m-0" style={{backgroundColor:'#FFE8B8', border:'none'}}></Dropdown.Toggle>
            <img src="/images/dog.png" alt="Profile" width="50" height="50" className="rounded-circle ms-1"/>
            <Dropdown.Menu style={{backgroundColor:'#FFE8B8', border:'none', textAlign:'center'}}>
              <Dropdown.Item onClick={() => props.login(true)}>Sign In</Dropdown.Item>
              <Dropdown.Item onClick={() => props.signup(true)}>Sign Up</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ fontWeight:'bold', fontSize:'20px',}}>
            <Nav.Link style={brown} onClick={() => props.login(true)}>Book your walk</Nav.Link>
            <Nav.Link style={brown} href="#meetup">Book your pups first meet up</Nav.Link>
            <Nav.Link style={brown} href="#about">About Wapping Walkies</Nav.Link>
            <Nav.Link style={brown} href="#map">Track the Wapping Walkies map</Nav.Link>
          </Nav>
          <Form className="flex mt-2">
            <Form.Control type="email" placeholder="email" className="me-2" aria-label="email"/>
            <div className='d-flex mt-1'>
                <Form.Control type="password" placeholder="password" className="me-2" aria-label="password"/>
                <Button variant="outline-dark">Login</Button> 
            </div>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navtabs;