import React, { useRef, useState } from "react"
import { Form, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/authContext"
import { Link } from "react-router-dom"
import Modal from 'react-bootstrap/Modal';

export default function ForgotPassword(props) {
  const [show, setShow] = useState(true); // State variable to control modal visibility
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  const handleClose = () => {
    setShow(false); // Update the state to close the modal
    if (props.onHide) {
      props.onHide(); // Call onHide from props if provided
    }
  };  

  return (
      <Modal show={props.show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title style={{textAlign:'center'}}>Password Reset</Modal.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
      </Modal.Header>
      <Modal.Body>         
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100 mt-3" type="submit">
            Reset Password
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="flex justify-content-center">
        <div className="w-100 text-center mt-3">
        Need an account? <Link onClick={() => { handleClose(); props.signup(true); }}>Sign Up</Link>
        </div>
      </Modal.Footer>
    </Modal>
  )
}