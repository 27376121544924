// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB0soXcDckOwQgxQNhn9pYp_JDbgY3r8GQ",
    authDomain: "wapping-walkies.firebaseapp.com",
    databaseURL: "https://wapping-walkies-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wapping-walkies",
    storageBucket: "wapping-walkies.appspot.com",
    messagingSenderId: "308235629868",
    appId: "1:308235629868:web:bae1ceb40fd02f06516889",
    measurementId: "G-VKB57H1L9B"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const db = getDatabase(app);
export const storage = getStorage(app);
export default app