import React, { useRef, useState, useEffect } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { useAuth } from "../contexts/authContext"
import { Link, useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Modal from 'react-bootstrap/Modal';

export default function Signup(props) {
  const [show, setShow] = useState(true); // State variable to control modal visibility
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const nameRef = useRef();
  const fileInputRef = useRef();
  const { signup, nameUpdate, photoUpdate } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up the auth observer
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Get the selected file from the input element
          const file = fileInputRef.current?.files[0];
          if (!file) {
            throw new Error("Please select a profile picture");
          }

          // Upload the photo to Firebase Storage
          const storage = getStorage();
          const storageRef = ref(storage, `images/${user.email}.png`);
          await uploadBytes(storageRef, file);

          // Get the download URL of the uploaded photo
          const downloadURL = await getDownloadURL(storageRef);

          // Update the user's profile with the photoURL and name
          await Promise.all([photoUpdate(downloadURL), nameUpdate(nameRef.current.value)]);

          // Navigate to the dashboard after successful sign up
          navigate("/mydogs");
        } catch (error) {
          setError(error.message || "Failed to create an account");
        } finally {
          setLoading(false);
        }
      }
    });

    // Clean up the observer when the component unmounts
    return () => unsubscribe();
  }, [nameUpdate, photoUpdate, navigate]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      // Sign up the user
      await signup(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      setError(error.message || "Failed to create an account");
      setLoading(false);
    }
  }

  const handleClose = () => {
    setShow(false); // Update the state to close the modal
    if (props.onHide) {
      props.onHide(); // Call onHide from props if provided
    }
  };

  const fgrStyle = {marginTop:'10px'}

  return (
    <Modal show={props.show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title style={{textAlign:'center'}}>Sign up to create your Pup Profile</Modal.Title>
        {error && <Alert variant="danger">{error}</Alert>}
      </Modal.Header>
      <Modal.Body>         
          <Form onSubmit={handleSubmit}>
            <Form.Group id="displayname">
              <Form.Label>Dog Name(s)</Form.Label>
              <Form.Control type="text" ref={nameRef} required/>
            </Form.Group>
            <Form.Group id="profile-picture" style={fgrStyle}>
              <Form.Label>Pooch Profile Picture</Form.Label>
              <Form.Control type="file" ref={fileInputRef} accept="image/*" />
            </Form.Group>
            <Form.Group id="poochname" style={fgrStyle}>
              <Form.Label>Your Name</Form.Label>
              <Form.Control type="text" ref={nameRef} required/>
            </Form.Group>
            <Form.Group id="email" style={fgrStyle}>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" style={fgrStyle}>
              <Form.Control type="password" ref={passwordRef} placeholder="Password" required />
            </Form.Group>
            <Form.Group id="password-confirm" >
              <Form.Control type="password" ref={passwordConfirmRef} placeholder="Password Confirmation" required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit" style={fgrStyle}>
              Sign Up
            </Button>
          </Form>
      </Modal.Body>
      <Modal.Footer className="flex justify-content-center">
        <div className="w-100 text-center mt-3">
        Already have an account? <Link onClick={() => { handleClose(); props.login(true); }}>Log In</Link>
        </div>
      </Modal.Footer>
    </Modal>
  );
}