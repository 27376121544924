import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import DashNav from './dashComponents/dashNav';
import Footer from '../components/footer/footer'

function Home() {
    const { currentUser} = useAuth()
    const [error, setError] = useState("")
    const [selectedDays, setSelectedDays] = useState([]);

    const dogNameRef = useRef();
    const dogNumRef = useRef();
    const ownerNameRef = useRef();
    const addressRef = useRef();
    const phoneRef = useRef();
    const startDateRef = useRef();
    const startTimeRef = useRef();
    const endDateRef = useRef();
  
    const handleDaysSelection = (day) => {
        // Toggle the selected state of the day
        setSelectedDays((prevSelectedDays) => {
          if (prevSelectedDays.includes(day)) {
            // Day is already selected, remove it
            return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
          } else {
            // Day is not selected, add it
            return [...prevSelectedDays, day];
          }
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const formSpreeEndpoint = 'https://formspree.io/f/moqzneyo';
    
        const bookingDetails = {
          dogName: dogNameRef.current.value,
          dogNum: dogNumRef.current.value,
          ownerName: ownerNameRef.current.value,
          owneraccname: currentUser.displayName,
          address: addressRef.current.value,
          phone: phoneRef.current.value,
          startDate: startDateRef.current.value,
          startTime: startTimeRef.current.value,
          endDate: endDateRef.current.value,
          email: currentUser.email,
          selectedDays: selectedDays.join(', '), // Convert array to a comma-separated string
        };
    
        try {
            const response = await fetch(formSpreeEndpoint, {
              method: 'POST',
              headers: {'Content-Type': 'application/json',},
              body: JSON.stringify({bookingDetails,}),
            });
      
            if (response.ok) {
              alert('Booking successful!'); // You can replace this with a more user-friendly notification
            } else {
              alert('Booking failed. Please try again.'); // You can replace this with a more user-friendly notification
            }
          } catch (error) {
            console.error('Error submitting subscription:', error);
            alert('An error occurred. Please try again later.'); // You can replace this with a more user-friendly notification
          }
      };

    const fgrStyle = {marginTop:'10px', marginBottom:'0'}
    return (
      <Container fluid className="p-0">
        <DashNav/>
        <Container fluid className="p-0">
          <Row className="p-0 py-4 m-0" style={{width: '100%', minHeight:'91vh'}}>
            <Col lg="6"  className="p-0 m-0 ps-xs-0 ps-lg-5 d-flex justify-content-center align-items-center" style={{minHeight:'80vh'}}>
              <Card style={{width:'100%',maxWidth:'550px', backgroundColor:'transparent', border:'none', padding:'1rem'}}>
                <Card.Title id="dashbooking" style={{textAlign:'center'}}>Book your walks here!!</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="dogName" style={fgrStyle}>
                        <div className="d-flex w-100">
                        <div className="w-100">
                        <Form.Label>Dog Name(s)</Form.Label>
                        <Form.Control type="text" ref={dogNameRef} required />
                        </div>
                        <div className="w-30">
                        <Form.Label>No. of Dogs</Form.Label>
                        <Form.Control type="number" ref={dogNumRef} required />
                        </div>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="ownerName">
                        <Form.Label style={fgrStyle}>Your Name</Form.Label>
                        <Form.Control type="text" ref={ownerNameRef} placeholder={currentUser.displayName}/>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label style={fgrStyle}>Phone Number</Form.Label>
                        <Form.Control type="text" ref={phoneRef} required />
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label style={fgrStyle}>Full Address</Form.Label>
                        <Form.Control type="text" ref={addressRef} required />
                    </Form.Group>
                    <Form.Group controlId="startDateTime">
                        <Form.Label style={fgrStyle}>Start Date and requested time of the walk</Form.Label>
                        <div className="d-flex">
                            <Form.Control type="date" ref={startDateRef} required />
                            <Form.Control type="time" ref={startTimeRef} required />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="daysSelection">
                        <Form.Label style={fgrStyle}>Select Days</Form.Label>
                        <div style={{maxWidth:'100%',display:'flex', justifyContent:'center',flexWrap:'wrap',}}>
                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                            <Button
                            key={day}
                            variant={selectedDays.includes(day) ? 'secondary' : 'outline-secondary'}
                            onClick={() => handleDaysSelection(day)}
                            className="me-1"
                            style={{color:'#000000', border:'1px, solid, #000000'}}
                            >
                            {day}
                            </Button>
                        ))}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="endDateTime">
                        <Form.Label style={fgrStyle}>End Date (leave empty if no end date)</Form.Label>
                        <Form.Control type="date" className="w-50" ref={endDateRef}/>
                    </Form.Group>
                    <Form.Group style={fgrStyle}>
                        {dogNumRef.current && (
                            <Form.Label style={{ textAlign: 'center', width: '100%' }}>{`£${dogNumRef.current.value * selectedDays.length * 17} per week`}</Form.Label>
                        )}
                    </Form.Group>
                    <Button className="w-100" type="submit" style={fgrStyle}>
                        Submit
                    </Button>
                </Form>
              </Card>
            </Col>
            <Col lg="6"  className="p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight:'60vh'}}>
              <Card style={{width:'100%',maxWidth:'550px', backgroundColor:'transparent', border:'none', padding:'1rem', textAlign:'center'}}>
                <Card.Title id="dashmeetup"> or Book an initial meet up here</Card.Title>
                <iframe
                  title="Wapping Walkies Calendar"
                  src="https://wappingwalkies.youcanbook.me/"
                  height="500"
                ></iframe>
              </Card>
            </Col>
          </Row>
          <Row className="p-0 py-4 m-0" style={{width: '100%'}}>
            <Col lg="12"  className="p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight:'10vh'}}>
              <Card style={{ backgroundColor:'transparent', border:'none', padding:'1rem'}}>
                <Card.Title id="dashmap" style={{backgroundColor:'#EBE2B5',padding:'5px', borderRadius:'20px'}}>Live Map Coming Soon!</Card.Title>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer/>
      </Container>
    );
  }
  
  export default Home