import React from "react"
import { AuthProvider } from "./pages/contexts/authContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/home"
import PrivateRoute from "./pages/privateRoute"
import DashboardApp from "./pages/dashboard/dashboardApp"
import Container from 'react-bootstrap/Container';

function App() {
  
  return (
    <Router>
        {/* <NavTabs /> */}
        <AuthProvider>
          <Container className="justify-content-center p-0 m-0" style={{display:'flex', minHeight: "100vh", maxWidth:"100%", backgroundColor: "#8DC4E6"}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mydogs/*"
                element={<PrivateRoute>
                          <DashboardApp />
                        </PrivateRoute>}
                />
            </Routes>
          </Container>
        </AuthProvider>
    </Router>
  )
}

export default App
