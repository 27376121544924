import React, { useState } from "react";
import { useAuth } from "../../contexts/authContext"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom"

function Navtabs() {
  const brown = {color: '#4E2D21'}
  const [error, setError] = useState("")
  const {logout } = useAuth()
  const navigate = useNavigate()
  const { currentUser} = useAuth()

  async function handleLogout() {
      setError("")
      try {
          await logout()
          navigate("/")
      } catch (error) {
          setError(error.message || "Failed to log out")
      }
  }

  return (
    <Navbar expand="none" className="py-2 m-0 px-0" style={{backgroundColor:'#FFE8B8', padding:'5px', minHeight:'9vh',}}>
      <Container fluid className='d-flex justify-content-space-between px-xs-1 px-lg-5'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" variant="light"/>
        <Navbar.Brand><img alt="logo" src="/images/wwdoglogo.png" height="36" className="d-inline-block align-top"></img><img alt="logo" src="/images/wwdashlogo.png" height="36" className="d-inline-block align-top"></img></Navbar.Brand>
        <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic" className="p-0 m-0" style={{backgroundColor:'#FFE8B8', border:'none'}}></Dropdown.Toggle>
            <img src={currentUser.photoURL} alt="Profile" width="50" height="50" className="rounded-circle ms-1"/>
            <Dropdown.Menu style={{backgroundColor:'#FFE8B8', border:'none', textAlign:'center'}}>
              <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ fontWeight:'bold', fontSize:'20px',}}>
            <Nav.Link style={brown} href="#dashbooking">Book your walk</Nav.Link>
            <Nav.Link style={brown} href="#dashmeetup">Book your pups first meet up</Nav.Link>
            <Nav.Link style={brown} href="#dashmap">Track the Wapping Walkies map</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navtabs;